@tailwind base;
@tailwind components;
@tailwind utilities;

.bg-image-zoom {
    transition: transform 0.5s ease, filter 0.5s ease;
}

.bg-image-zoom:hover {
    transform: scale(1.1);
    filter: brightness(50%);
}